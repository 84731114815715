import auth from './auth.js';
import axios from 'axios';

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;

export default {
    
    /**
     * ============= Public API ====================
     */

    createAppointment(appointment) {
        return axios.post(`/public/v1/appointments`,
            appointment,
            {
                headers: {
                'Content-Type': 'application/json',
            }})
          .then(response => response.data);
    },

    confirmEmail(appointmentUuid, code) {
        return axios.post(`/public/v1/appointments/${appointmentUuid}/confirmEmail/${code}`,
            null,
            { headers: { 'Content-Type': 'application/json', }})
          .then(response => response.data);
    },

    getAppointments(uuid) {
        return axios.get(`/public/v1/appointments/${uuid}`)
            .then(response => response.data);
    },


    queryAvailableSlots(fromDate, toDate) {
        return axios.get(`/public/v1/scheduling/slots?fromDate=${fromDate.getTime()}&toDate=${toDate.getTime()}`)
            .then(response => response.data);
    },


    /**
     * ============= User(s) API ====================
     */

    getCurrentUser(accessToken) {
        return axios.get(`/v1/user`, {
            headers: { 'Authorization': `Bearer ${accessToken ? accessToken : auth.getAccessToken()}` }})
            .then(response => response.data);
    },

    setProfilePic(files) {

        let data = new FormData()
        data.append('file', files[0]);

        return axios.post(`/v1/user/profilePic`,
            data,
            {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.getAccessToken()}`
            }})
          .then(response => response.data);
    },

    updateUser(userUuid, user) {
        return axios.put(`/v1/users/${userUuid}`, 
            user,
            {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.getAccessToken()}`,
            }})
            .then(response => response.data);
    },


    /**
     * ============= Authorized API ====================
     */

    getAppointment(appointmentUuid) {
        return axios.get(`/v1/appointments/${appointmentUuid}`, {
            headers: { 'Authorization': `Bearer ${auth.getAccessToken()}` }})
            .then(response => response.data);
    },


    queryConsultations(page, pageSize) {
        return axios.get(`/v1/consultations?page=${page}&pageSize=${pageSize}`, {
            headers: { 'Authorization': `Bearer ${auth.getAccessToken()}` }})
            .then(response => response.data);
    },


    getConsultation(consultationUuid) {
        return axios.get(`/v1/consultations/${consultationUuid}`, {
            headers: { 'Authorization': `Bearer ${auth.getAccessToken()}` }})
            .then(response => response.data);
    },

    getPatientByConsultation(consultationUuid) {
        return axios.get(`/v1/consultations/${consultationUuid}/patient`, {
            headers: { 'Authorization': `Bearer ${auth.getAccessToken()}` }})
            .then(response => response.data);
    },

    setNoIndication(consultationUuid, noIndicationHr) {
        return axios.put(`/v1/consultations/${consultationUuid}/noIndication`, 
            noIndicationHr,
            {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.getAccessToken()}`,
            }})
            .then(response => response.data);
    },

    setInstructionsRead(appointmentUuid) {
        return axios.put(`/v1/appointments/${appointmentUuid}/readInstructions`, null,
            {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.getAccessToken()}`
            }})
          .then(response => response.data);
    },

    setAppointmentStatus(appointmentUuid, appointment) {
        return axios.put(`/v1/appointments/${appointmentUuid}/status`,
            appointment,
            {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.getAccessToken()}`
            }})
          .then(response => response.data);
    },

    sendNewAppointmentInvitation(appointmentUuid) {
        return axios.post(`/v1/appointments/${appointmentUuid}/invitation`,
            {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.getAccessToken()}`
            }})
          .then(response => response.data);
    },

    createSlotTemplate(slotTemplate) {
        return axios.post(`/v1/scheduling/templates`,
            slotTemplate,
            {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.getAccessToken()}`
            }})
          .then(response => response.data);
    },

    querySlotTemplates(atDate) {
        return axios.get(`/v1/scheduling/templates?atDate=${atDate.getTime()}`, {
            headers: { 'Authorization': `Bearer ${auth.getAccessToken()}` }})
            .then(response => response.data);
    },

    querySlots(fromDate, toDate) {
        return axios.get(`/v1/scheduling/slots?fromDate=${fromDate.getTime()}&toDate=${toDate.getTime()}`, {
            headers: { 'Authorization': `Bearer ${auth.getAccessToken()}` }})
                .then(response => response.data);
    },

    deleteSlotTemplate(uuid) {
        return axios.delete(`/v1/scheduling/templates/${uuid}`, {
            headers: { 'Authorization': `Bearer ${auth.getAccessToken()}` }});
    },

    queryAppointments(fromDate, toDate) {
        return axios.get(`/v1/appointments?fromDate=${fromDate.getTime()}&toDate=${toDate.getTime()}`, {
            headers: { 'Authorization': `Bearer ${auth.getAccessToken()}` }})
            .then(response => response.data);
    },

    queryAppointmentsWithConsultations(fromDate, toDate) {
        return axios.get(`/v1/consultations/appointments?fromDate=${fromDate.getTime()}&toDate=${toDate.getTime()}`, {
            headers: { 'Authorization': `Bearer ${auth.getAccessToken()}` }})
            .then(response => response.data);
    },

    queryPatients(query, page, pageSize) {
        return axios.get(`/v1/patients?query=${query}&page=${page}&pageSize=${pageSize}`, {
            headers: { 'Authorization': `Bearer ${auth.getAccessToken()}` }})
            .then(response => response.data);
    },

    getPatient(uuid) {
        return axios.get(`/v1/patients/${uuid}`, {
            headers: { 'Authorization': `Bearer ${auth.getAccessToken()}` }})
            .then(response => response.data);
    },

    updatePatient(uuid, patient) {
        return axios.put(`/v1/patients/${uuid}`,
            patient,
            {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.getAccessToken()}`
            }})
          .then(response => response.data);
    },

    addHealthRecord(patientUuid, hr) {
        return axios.post(`/v1/patients/${patientUuid}/healthrecords`,
            hr,
            {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.getAccessToken()}`
            }})
          .then(response => response.data);
    },

    uploadHealthRecordFile(patientUuid, hr, files) {

        let data = new FormData()
        data.append('file', files[0]);
        data.append('healthRecord', JSON.stringify(hr));

        return axios.post(`/v1/patients/${patientUuid}/healthrecords/files`,
            data,
            {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.getAccessToken()}`
            }})
          .then(response => response.data);
    },

    uploadPatientReport(consultationUuid, hr, files) {

        let data = new FormData()
        data.append('file', files[0]);
        data.append('healthRecord', JSON.stringify(hr));

        return axios.post(`/v1/consultations/${consultationUuid}/patientReport`,
            data,
            {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.getAccessToken()}`
            }})
          .then(response => response.data);
    },

    downloadHealthRecordFile(healthRecordUuid, interFileKey, originalFileName) {
        return axios.get(`/v1/healthrecords/${healthRecordUuid}/files/${interFileKey}`, {
            headers: { 'Authorization': `Bearer ${auth.getAccessToken()}` },
            responseType: 'blob'})
            .then(response => this.downloadFile(response, originalFileName));
    },

    setHealthRecordReviewed(hrUuid) {
        return axios.put(`/v1/healthrecords/${hrUuid}/reviewed`, null,
            {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.getAccessToken()}`
            }})
          .then(response => response.data);
    },

    createOrder(uuid, order) {
        return axios.post(`/v1/consultations/${uuid}/orders`,
            order,
            {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.getAccessToken()}`
            }})
          .then(response => response.data);
    },

    getOrder(orderUuid) {
        return axios.get(`/v1/orders/${orderUuid}`, {
            headers: { 'Authorization': `Bearer ${auth.getAccessToken()}` }})
            .then(response => response.data);
    },

    queryOrders(orderStatus, refreshShippingStatus) {
        return axios.get(`/v1/orders?orderStatus=${orderStatus}&refreshShippingStatus=${refreshShippingStatus}`, {
            headers: { 'Authorization': `Bearer ${auth.getAccessToken()}` }})
            .then(response => response.data);
    },

    generateTestkitLabels(startRow, orderUuids) {
        return axios.get(`/v1/orders/testKitLabels?startRow=${startRow}&${orderUuids.map((n) => `orderUuids=${n}`).join('&')}`, {
            headers: { 'Authorization': `Bearer ${auth.getAccessToken()}` },
            responseType: 'blob'})
            .then(response => {
                //
                // this is a hack - but I think a necessary one :(
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'TestKit-Labels.pdf');
                document.body.appendChild(link);
                link.click();
            });
    },

    generateDHLOrderCSV(orderUuids) {
        return axios.get(`/v1/orders/DHLOrderCSV?${orderUuids.map((n) => `orderUuids=${n}`).join('&')}`, {
            headers: { 'Authorization': `Bearer ${auth.getAccessToken()}` },
            responseType: 'blob'})
            .then(response => {
                //
                // this is a hack - but I think a necessary one :(
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'DHL Order.csv');
                document.body.appendChild(link);
                link.click();
            });
    },

    updateOrderTrackingNumbers(orderUuid, trackingNumbers) {
        return axios.put(`/v1/orders/${orderUuid}/trackingNumbers`,
            trackingNumbers,
            {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.getAccessToken()}`
            }})
          .then(response => response.data);
    },

    updateOrderShippingStatus(orderUuid, direction, status) {
        return axios.put(`/v1/orders/${orderUuid}/${direction}/${status}`, null, {
            headers: { 'Authorization': `Bearer ${auth.getAccessToken()}` }})
            .then(response => response.data);
    },

    queryVisitors() {
        return axios.get(`/v1/waitingroom`, {
            headers: { 'Authorization': `Bearer ${auth.getAccessToken()}` }})
            .then(response => response.data);
    },

    pollVisitors(subscriberId) {
        return axios.get(`/v1/waitingroom/subscribe?subscriberId=${subscriberId}`, {
            headers: { 'Authorization': `Bearer ${auth.getAccessToken()}` }})
            .then(response => response.data);
    },

    queryVisitor(appointmentUuid) {
        return axios.get(`/v1/waitingroom/${appointmentUuid}`, {
            headers: { 'Authorization': `Bearer ${auth.getAccessToken()}` }})
            .then(response => response.data);
    },

    pollVisitor(subscriberId, appointmentUuid) {
        return axios.get(`/v1/waitingroom/subscribe/${appointmentUuid}?subscriberId=${subscriberId}`, {
            headers: { 'Authorization': `Bearer ${auth.getAccessToken()}` }})
            .then(response => response.data);
    },

    downloadFile(response, fileName) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    },

    /**
     * ============= Boarding API ====================
     */

     uploadMedia(files) {

        let data = new FormData()
        data.append('file', files[0]);

        return axios.post(`/v1/boarding/tasks/templates/media`,
            data,
            {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.getAccessToken()}`
            }})
          .then(response => response.data);
    },

    getTaskTemplate(taskTemplateUuid) {
        return axios.get(`/v1/boarding/tasks/templates/${taskTemplateUuid}`, {
            headers: { 'Authorization': `Bearer ${auth.getAccessToken()}` }})
            .then(response => response.data);
    },

    queryTaskTemplates() {
        return axios.get(`/v1/boarding/tasks/templates`, {
            headers: { 'Authorization': `Bearer ${auth.getAccessToken()}` }})
            .then(response => response.data);
    },

    updateTaskTemplate(taskTemplate) {
        return axios.put(`/v1/boarding/tasks/templates`,
        taskTemplate,
            {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.getAccessToken()}`
            }})
          .then(response => response.data);
    },

    queryBoardingTemplates() {
        return axios.get(`/v1/boarding/templates`, {
            headers: { 'Authorization': `Bearer ${auth.getAccessToken()}` }})
            .then(response => response.data);
    },

    getBoardingTemplate(boardingTemplateUuid) {
        return axios.get(`/v1/boarding/templates/${boardingTemplateUuid}`, {
            headers: { 'Authorization': `Bearer ${auth.getAccessToken()}` }})
            .then(response => response.data);
    },

    saveBoardingTemplate(boardingTemplate) {
        return axios.put(`/v1/boarding/templates`,
        boardingTemplate,
            {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.getAccessToken()}`
            }})
          .then(response => response.data);
    },

    startBoarding(boardingTemplateUuid, context) {
        return axios.post(`/v1/boarding/templates/${boardingTemplateUuid}/start`,
        context,
            {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.getAccessToken()}`
            }})
            .then(response => response.data);
    },

    queryTasks(boardingUuid, role) {
        return axios.get(`/public/v1/boarding/${boardingUuid}/tasks?role=${encodeURIComponent(role)}`).
            then(response => response.data);
    },

    setTaskDone(taskUuid) {
        return axios.post(`/public/v1/boarding/tasks/${taskUuid}/done`,
            {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.getAccessToken()}`
            }})
            .then(response => response.data);
    },

    queryAllTasks(status, search) {
        return axios.get(`/v1/boarding/tasks?status=${status}&search=${encodeURIComponent(search)}`, {
            headers: { 'Authorization': `Bearer ${auth.getAccessToken()}` }}).
                then(response => response.data);
    },

    getTask(taskUuid) {
        return axios.get(`/public/v1/boarding/tasks/${taskUuid}`).
            then(response => response.data);
    },

    getBoardingStats() {
        return axios.get(`/public/v1/boarding/tasks/stats`).
            then(response => response.data);
    },
};
