<template>

<Toolbar :activeMenu="'BoardingTemplates'"/>

<!-- toast-->
<div class="position-fixed start-50 translate-middle p-3" style="z-index: 5000; top: 5rem">
  <div ref="saveToastRef" class="toast align-items-center text-white bg-primary border-0" style="width: 600px !important; max-width: 600px !important;" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="d-flex p-4">
      <div class="toast-body">
        <h5>Boarding Vorlage wurde gespeichert</h5>
      </div>
      <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
  </div>
</div>

<div class="container-fluid p-0">

  <div class="row g-0 vh-100">

    <!-- template -->
    <div class="col-9">

      <div class="vh-100 overflow-auto">
        
        <!-- Headline -->
        <div class="header sticky-top">
          <BusyIndicator :busy="state.busy"></BusyIndicator>
          <div class="container px-5 pt-5">
              <h1 class="display-5">
              <i class="bi-person-lines-fill pe-2 text-primary" role="img" ></i>
              {{ state.template.name }} <span class="text-primary">{{ state.changed ? '*' : '' }}</span>
            </h1>
            <span class="lead">{{ state.template.description }}</span>
          </div>
        </div>

        <div class="container px-5 py-5">

          <!-- general -->
          <h4 class="pt-4 pb-2">
            <i class="bi-list-task pe-2 text-primary"></i>Beschreibung
            <div class="float-end">
              <button class="btn btn-primary" :disabled="!state.changed || state.busy" @click="save()">
                <span>Speichern</span>
              </button>
            </div>
          </h4>

          <div class="row">
            <div class="col-12">
              <label for="inputName" class="form-label">Name</label>
              <input type="text" class="form-control" v-model="state.template.name" @input="state.changed = true" placeholder="Aufgaben Überschrift">
            </div>
            <div class="col-12 mt-4">
              <label for="inputName" class="form-label">Beschreibung</label>
              <textarea ref="description" class="form-control" v-model="state.template.description" @input="state.changed = true" placeholder="Beschreiben Sie die Aufgabe im Detail"></textarea>
            </div>
          </div>

          <!-- pre-boarding tasks -->
          <h4 class="pt-4 pb-2"><i class="bi-caret-left pe-2 text-primary"></i>Pre-Boarding Aufgaben</h4>
          <div class="bg-light border p-4 rounded">
            <div v-if="state.preBoarding.length == 0" class="bg-white rounded p-4 text-center">
              <span class="text-muted">Um eine Aufgabe hinzuzufügen, wählen Sie bitte eine Aufgabe aus der entsprechenden Kategorie aus der Aufgaben Gallerie rechts aus.</span>
            </div>
            <TransitionGroup>
              <BoardingTemplateStep v-for="(step, idx) in state.preBoarding" :key="step" :order="idx" :step="step" ref="preBoardingRefs" offsetInDaysLabel="Tage vor Arbeitsbeginn" @onRemove="state.preBoarding.splice($event, 1); state.changed = true;" @onChanged="state.changed = true"></BoardingTemplateStep>
            </TransitionGroup>
          </div>

          <!-- on-boarding tasks -->
          <h4 class="pt-4 pb-2"><i class="bi-app pe-2 text-primary"></i>On-Boarding Aufgaben</h4>
          <div class="bg-light border p-4 rounded">
            <div v-if="state.onBoarding.length == 0" class="bg-white rounded p-4 text-center">
              <span class="text-muted">Um eine Aufgabe hinzuzufügen, wählen Sie bitte eine Aufgabe aus der entsprechenden Kategorie aus der Aufgaben Gallerie rechts aus.</span>
            </div>
            <TransitionGroup>
              <BoardingTemplateStep v-for="(step, idx) in state.onBoarding" :key="step" :order="idx" :step="step" ref="onBoardingRefs" offsetInDaysLabel="Tage nach Arbeitsbeginn" @onRemove="state.onBoarding.splice($event, 1); state.changed = true;" @onChanged="state.changed = true"></BoardingTemplateStep>
            </TransitionGroup>
          </div>

          <!-- off-boarding tasks -->
          <h4 class="pt-4 pb-2"><i class="bi-caret-right pe-2 text-primary"></i>Off-Boarding Aufgaben</h4>
          <div class="bg-light border p-4 rounded">
            <div v-if="state.offBoarding.length == 0" class="bg-white rounded p-4 text-center">
              <span class="text-muted">Um eine Aufgabe hinzuzufügen, wählen Sie bitte eine Aufgabe aus der entsprechenden Kategorie aus der Aufgaben Gallerie rechts aus.</span>
            </div>
            <TransitionGroup>
              <BoardingTemplateStep v-for="(step, idx)  in state.offBoarding" :key="step" :order="idx" :step="step" ref="offBoardingRefs" offsetInDaysLabel="Tage vor Arbeitsende" @onRemove="state.offBoarding.splice($event, 1); state.changed = true;" @onChanged="state.changed = true"></BoardingTemplateStep>
            </TransitionGroup>
          </div>
        </div>

      </div>
    </div>

    <!-- list of templates -->
    <div class="col-3 bg-light shadow">
      <div class="vh-100 overflow-auto">

        <!-- search -->
        <div class="p-4 sticky-top bg-light">
          <div class="px-2">
            <h5>
              <i class="bi-grid pe-2 text-primary" role="img" ></i>Aufgaben Gallerie
            </h5>
            <div class="d-flex mt-4">
              <input type="text" class="form-control me-2" placeholder="Aufgabe suchen">
              <button class="btn btn-primary">Neu</button>
            </div>
          </div>
        </div>

        <BoardingTemplateSelection @onSelected="addStep($event)" @onEdit="safeRedirect($router.push({ path: `/taskTemplates/${$event}` }))"></BoardingTemplateSelection>
      </div>
    </div>

  </div>
</div>
</template>

<script>

import { useRoute, useRouter } from 'vue-router'
import { reactive, ref, watch } from 'vue'
import { Toast } from 'bootstrap'

import log from '@/modules/logging.js';
// import utils from '@/modules/utils.js';

import Toolbar from '@/components/Toolbar.vue'
import BusyIndicator from '@/components/BusyIndicator.vue'

import BoardingTemplateStep from './BoardingTemplateStep.vue'
import BoardingTemplateSelection from './BoardingTemplateSelection.vue'
import api from '../../modules/api';

export default {
  name: 'BoardingTemplate',
  components: {
     Toolbar,
     BoardingTemplateStep,
     BoardingTemplateSelection,
     BusyIndicator
  },
  setup() {

    //
    // keep track of route
    const route = useRoute();
    watch(() => route.path, () => {
      query();
    });
    const router = useRouter();

    const saveToastRef = ref();

    const preBoardingRefs = ref([]);
    const onBoardingRefs = ref([]);
    const offBoardingRefs = ref([]);

    const state = reactive({
      busy: false,
      changed: false,
      template: newTemplate(),
      preBoarding: [],
      onBoarding: [],
      offBoarding: [],
    });


    function addStep(taskTemplate) {

      var step = {

          taskTemplate: taskTemplate,

          //
          // take over pre-settings
          responsibleType: taskTemplate.responsibleType,
          responsible: taskTemplate.responsible,
          offsetInDays: taskTemplate.offsetInDays
      };

      if (taskTemplate.type == 'PreBoarding') {
        log.info("Adding task template to boaring template pre-boarding steps: {}", taskTemplate);
        state.preBoarding.push(step);
      }
      else if (taskTemplate.type == 'OnBoarding') {
        log.info("Adding task template to boaring template on-boarding steps: {}", taskTemplate);
        state.onBoarding.push(step);
      }
      else {
        log.info("Adding task template to boaring template off-boarding steps: {}", taskTemplate);
        state.offBoarding.push(step);
      }

      state.changed = true;
    }

    function save() {

      var boardingTemplate = getTemplate();
      log.trace("BoardingTemaplte:save() Saving template {}", boardingTemplate);

      state.busy = true;

      api.saveBoardingTemplate(boardingTemplate).then(response => {

        log.trace("BoardingTemaplte:save() Boarding Template saved: {}", response);
        setTemplate(response);

        state.busy = false;
        state.changed = false;

        var toast = new Toast(saveToastRef.value);
        toast.show();

      router.replace({ path: '/boardingTemplates/' + state.template.uuid });
      });
    }

    function query() {
      log.trace("TaskTemplate:query()");
      let uuid = extractUuidFromRoute();
      if (uuid && uuid != 'New') {
        queryByUuid(uuid);
      }
      else {
        state.template = newTemplate();
        state.changed = true;
      }
    }

    function extractUuidFromRoute() {
      const path = route.path.split("/").filter(s => s.length > 0);
      if (path[0] == 'boardingTemplates' && path[1]) {
        return path[1];
      }
      return null;
    }

    function queryByUuid(uuid) {
      api.getBoardingTemplate(uuid).then(response => {
        log.trace("BoardingTemplate:queryByUuid() Fetched boarding template: {}", response);
        setTemplate(response);
      });
    }

    function getTemplate() {

      const boardingTemplate = {
        uuid: state.template.uuid,
        name: state.template.name,
        description: state.template.description,
        steps: []
      };

      preBoardingRefs.value.forEach(e => boardingTemplate.steps.push(e.getStep()));
      onBoardingRefs.value.forEach(e => boardingTemplate.steps.push(e.getStep()));
      offBoardingRefs.value.forEach(e => boardingTemplate.steps.push(e.getStep()));

      return boardingTemplate;
    }

    function setTemplate(response) {
      state.template = response;
      state.preBoarding = [];
      state.template.steps.forEach(step => { if (step.taskTemplate.type == 'PreBoarding') state.preBoarding.push(step); });
      state.onBoarding = [];
      state.template.steps.forEach(step => { if (step.taskTemplate.type == 'OnBoarding') state.onBoarding.push(step); });
      state.offBoarding = [];
      state.template.steps.forEach(step => { if (step.taskTemplate.type == 'OffBoarding') state.offBoarding.push(step); });
    }

    function newTemplate() {
      return {
        uuid: null,
        name: "Neue Boarding Vorlage",
        description: "Beschreibung des Job Profil für diese Boarding Vorlage"
      };
    }

    function safeRedirect(f) {
      f();
    }

    query();

    return {
      saveToastRef,
      preBoardingRefs,
      onBoardingRefs,
      offBoardingRefs,
      state,
      addStep,
      save,
      safeRedirect,
    };
  }
};
</script>
