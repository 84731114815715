<template>
<div class="container overflow-auto">
    <SimpleTask :task="state.task"></SimpleTask>
</div>
</template>

<script>

import SimpleTask from './SimpleTask';

import { reactive, watch } from 'vue'
import { useRoute } from 'vue-router'

import log from '@/modules/logging.js';
import api from '@/modules/api.js';

export default {
  name: 'TaskView',
  components: {
    SimpleTask,
  },
  setup() {

    //
    // keep track of route
    const route = useRoute();
    watch(() => route.path, () => {
      query();
    });

    const state = reactive({
      task: null
    });

    function extractUuidFromRoute() {
      const path = route.path.split("/").filter(s => s.length > 0);
      if (path[0] == 'task' && path[1]) {
        return path[1];
      }
      return null;
    }

    function query() {
      api.getTask(extractUuidFromRoute()).then(response => {
        log.trace("TaskView:query() Fetched task {}", response);
        state.task = response;
      });
    }

    query();

    return {
      state,
    };
  }
};
</script>