<template>

<!-- Order testkit dialog -->
<div class="modal" ref="dialogRef" tabindex="-1">
<div class="modal-dialog modal-lg">
  <div class="modal-content">

    <div class="modal-header">
      <h5 class="modal-title">Boarding starten</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>

    <div class="modal-body container">

      <div class="row">

        <div class="col-12">
          <label for="title" class="form-label mt-2">Anrede</label>
          <input type="text" class="form-control" v-model="state.person.title" placeholder="">
        </div>

        <div class="col-12">
          <label for="firstName" class="form-label mt-2">Vorname</label>
          <input type="text" class="form-control" v-model="state.person.firstName" placeholder="">
        </div>

        <div class="col-12">
          <label for="lastName" class="form-label mt-2">Nachname</label>
          <input type="text" class="form-control" v-model="state.person.lastName" placeholder="">
        </div>

        <div class="col-12">
          <label for="email" class="form-label mt-2">Private E-Mail</label>
          <input type="text" class="form-control" v-model="state.person.privateEmail" placeholder="">
        </div>

        <div class="col-12">
          <label for="email" class="form-label mt-2">Firmen E-Mail</label>
          <input type="text" class="form-control" v-model="state.person.email" placeholder="">
        </div>

        <div class="col-12">
          <label for="startDate" class="form-label mt-2">Start Datum</label>
          <input type="text" class="form-control" v-model="state.person.startDate" placeholder="">
        </div>

        <div class="col-12">
          <label for="role" class="form-label mt-2">Rolle</label>
          <input type="text" class="form-control" v-model="state.person.role" placeholder="">
        </div>
      
        <div class="col-12">
          <label for="department" class="form-label mt-2">Abteilung</label>
          <input type="text" class="form-control" v-model="state.person.department" placeholder="">
        </div>

        <div class="col-12">
          <div class="mt-4 bg-light p-4 rounded font-monospace">
            {{ state.boardingUrl }} <a class="ms-2 clickable" @click="navigator.clipboard.writeText( state.boardingUrl);"><i class="bi-clipboard-plus"></i></a>
          </div>
        </div>

      </div>

      <div class="float-end mt-4">
        <button class="btn btn-primary" :disabled="state.busy" @click="startBoarding()">
          <div v-if="state.busy" class="spinner-border spinner-border-sm text-light" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          Boarding Prozess starten
        </button>
      </div>

    </div>

  </div>
</div>
</div>

</template>

<script>

import { reactive, ref } from 'vue'

import { Modal } from 'bootstrap'

import api from '../../modules/api';
import log from '../../modules/logging';

export default {
  name: 'StartBoardingDialog',
  setup() {

    //
    // ref to order test kit dialog
    const dialogRef = ref(null);
    var dialogModal = null;

    const state = reactive({

      busy: false,

      boardingTemplateUuid: null,

      person: {
      },

      boardingUrl: null
    });

    function startBoarding() {

      state.busy = true;
      state.boardingUrl = "";

      var context = {
        person: state.person
      };
      context.person.fullName = state.person.firstName + ' ' + state.person.lastName;

      log.trace("StartBoardingDialog:startBoarding() Start Boarding with context {}", context);
      api.startBoarding(state.boardingTemplateUuid, context).then(response => {
        state.boardingUrl = response;
        state.busy = false;
      });
    }

    function show(boardingTemplateUuid) {
      if (!dialogModal) {
        dialogModal = new Modal(dialogRef.value);
      }
      state.boardingTemplateUuid = boardingTemplateUuid;
      dialogModal.show();
    }

    return {
      dialogRef,
      state,
      startBoarding,
      show,
    };
  }
};
</script>
