<template>

<Toolbar :activeMenu="'BoardingTemplates'"/>

<StartBoardingDialog ref="startBoardingDialogRef"></StartBoardingDialog>

<div class="w-100 overflow-auto">

<div class="header">

  <div class="container px-5 pt-5">

    <!-- Headline -->
    <h1 class="display-5">
      <i class="bi-list-task pe-2 text-primary" role="img" ></i>
      Boarding Profile
    </h1>
    <span class="lead">Verwalten Sie hier Ihre unterschiedlichen Pre-, On- und Offboarding Profile</span>

  </div>
</div>

<div class="container px-5 py-5">
  <div class="float-end">
    <button class="btn btn-primary" @click="$router.push({ path: `/boardingTemplates/New` });">Neues Boarding Profil anlegen</button>
  </div>
</div>

<div v-if="state.templates.length > 0" class="container px-5 py-4">
  <div class="border shadow">
    <table class="table table-hover rounded">
      <tbody>
        <TransitionGroup>
        <tr v-for="template in state.templates" :key="template">
          <td class="p-4 clickable" @click="$router.push({ path: `/boardingTemplates/${template.uuid}` });">
            <h4><i class="bi-person-lines-fill me-3 text-primary"></i>{{ template.name }}</h4>
            <span>{{ template.description }}</span>
          </td>
          <td class="p-4" align="right">
              <a class="clickable" @click="startBoarding(template.uuid)"><h3><i class="bi-play-btn text-primary"></i></h3></a>
          </td>
        </tr>
        </TransitionGroup>
      </tbody>
    </table>
  </div>
</div>

</div>

</template>

<script>

import { reactive, ref } from 'vue'

import log from '@/modules/logging.js'
import api from '@/modules/api.js'

import Toolbar from '@/components/Toolbar.vue'
import StartBoardingDialog from './StartBoardingDialog.vue'


export default {
  name: 'BoardingTemplateList',
  components: {
     Toolbar,
     StartBoardingDialog,
  },
  setup() {

    const startBoardingDialogRef = ref(null);

    const state = reactive({
      templates: []
    });

    function query() {
      api.queryBoardingTemplates().then(response => { 
        log.trace("BoardingTemplate:query() boarding template loaded {}", response);
        state.templates = response;
      });
    }

    function startBoarding(boardingTemplateUuid) {
      startBoardingDialogRef.value.show(boardingTemplateUuid);
    }

    query();

    return {
      startBoardingDialogRef,
      state,
      startBoarding,
    };
  }
};
</script>
