<template>

<Toolbar :activeMenu="'TaskTemplates'"/>

<div class="container-fluid p-0">

  <div class="row g-0 vh-100">

    <!-- template -->
    <div class="col-9">

      <div class="vh-100 overflow-auto">
          
        <!-- Headline -->
        <div class="header sticky-top">
          <BusyIndicator :busy="state.busy"></BusyIndicator>
          <div class="container px-5 pt-5">
              <h1 class="display-5">
              <i class="bi-check2-square pe-2 text-primary" role="img" ></i>
              {{ state.template.headline }}
            </h1>
            <span class="lead">Entwerfen oder bearbeiten Sie hier eine Aufgabe für ein Team oder Mitarbeiter</span>
          </div>
        </div>

        <div class="container px-5 pt-5">

          <div class="float-start my-4">

          <h4 class="pb-2"><i class="bi-display pe-3 text-primary"></i>
            Anzeige
            <div class="float-end">
                <button class="btn btn-primary" @click="saveTaskTemplate()">
                <div v-if="state.busy" class="spinner-border-sm text-light me-2" role="status">
                  <span class="visually-hidden">Speichern...</span>
                </div>                
                Speichern
              </button>
            </div>
           </h4>

          <div class="row">

            <div class="col-md-12">
              <div>
                <label for="inputName" class="form-label ">Kategorie</label>
              </div>
              <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                <input type="radio" class="btn-check" id="PreBoarding" autocomplete="off" value="PreBoarding" v-model="state.template.type">
                <label class="btn btn-outline-primary" for="PreBoarding">Pre-Boarding</label>

                <input type="radio" class="btn-check" id="OnBoarding" autocomplete="off" value="OnBoarding" v-model="state.template.type">
                <label class="btn btn-outline-primary" for="OnBoarding">On-Boarding</label>

                <input type="radio" class="btn-check" id="OffBoarding" autocomplete="off" value="OffBoarding" v-model="state.template.type">
                <label class="btn btn-outline-primary" for="OffBoarding">Off-Boarding</label>
              </div>
            </div>

            <div class="col-md-12 mt-4">
              <label for="inputName" class="form-label">Überschrift</label>
              <input type="text" class="form-control" v-model="state.template.headline" placeholder="Aufgaben Überschrift">
            </div>

            <div class="col-md-12 mt-4">
              <label for="inputName" class="form-label">Beschreibung</label>
              <div class="dropdown float-end">
                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  Variable einfügen
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li><a class="dropdown-item" href="#" @click="insertVar('{{ person.title }}');">Anrede Mitarbeiter</a></li>
                  <li><a class="dropdown-item" href="#" @click="insertVar('{{ person.fullName }}');">Vorname Name Mitarbeiter</a></li>
                  <li><a class="dropdown-item" href="#" @click="insertVar('{{ person.firstName }}');">Vorname Mitarbeiter</a></li>
                  <li><a class="dropdown-item" href="#" @click="insertVar('{{ person.lastName }}');">Nachname Mitarbeiter</a></li>
                  <li><a class="dropdown-item" href="#" @click="insertVar('{{ person.email }}');">Firmen Email</a></li>
                  <li><a class="dropdown-item" href="#" @click="insertVar('{{ person.privateEmail }}');">Private Email</a></li>
                  <li><a class="dropdown-item" href="#" @click="insertVar('{{ person.role }}');">Rolle Mitarbeiter</a></li>
                  <li><a class="dropdown-item" href="#" @click="insertVar('{{ person.department }}');">Abteilung Mitarbeiter</a></li>
                  <li><a class="dropdown-item" href="#" @click="insertVar('{{ person.startDate }}');">Startdatum Mitarbeiter</a></li>
                </ul>
              </div>              
              <textarea ref="description" class="form-control mt-3" style="height: 5rem" v-model="state.template.description" placeholder="Beschreiben Sie die Aufgabe im Detail"></textarea>
            </div>

            <div class="col-md-12 mt-4">
              <div class="bg-white p-4 border text-center">
                <span class="lead">Bitte wählen Sie ein Bild, dass als Teil der Aufgabe angezeigt wird aus</span>
                <div class="mt-4">
                  <input ref="fileUpload" type="file" id="fileElem" accept=".jpg, .png" style="display:none" @change="uploadMedia()">
                  <button class="btn btn-primary" @click="fileUpload.click()">
                    <span v-if="state.uploadBusy" class="spinner-border spinner-border-sm text-light me-2"></span>
                    Bild auswählen
                  </button>
                </div>
              </div>
            </div>

            <div class="col-md-12 mt-4">
              <label for="inputName" class="form-label">Voraussichtliche Dauer</label>
              <input type="text" class="form-control" v-model="state.template.duration" placeholder="Dauer in Minuten oder Stunden und Einheit">
            </div>

            <div class="col-sm-12 mt-4">
              <label for="inputName" class="form-label">Antwort</label>
              <select class="form-select" v-model="state.template.defaultAction" aria-label="Default select example">
                <option value="JA, ERLEDIGT" selected>Ja, erledigt</option>
                <option value="OK, GELESEN">Ok, gelesen</option>
                <option value="JA, VERSTANDEN">Ja, verstanden</option>
                <option value="DANKE">Danke</option>
                <option value="ALLES KLAR">Alles Klar</option>
              </select>              
            </div>

            <!--
              Presettings
            -->

            <div class="col-md-12 mt-5">
              <h4 class="pb-2"><i class="bi-sliders pe-3 text-primary"></i>Voreinstellungen</h4>
            </div>

            <div class="col-sm-6">
              <label for="inputName" class="form-label">Verantwortlich</label>
              <div class="input-group mb-3">
                <button class="btn btn-outline-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Auswahl</button>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" href="#" @click="selectResponsible('Employee')">Mitarbeiter</a></li>
                  <li><a class="dropdown-item" href="#" @click="selectResponsible('Manager')">Manager</a></li>
                  <li><a class="dropdown-item" href="#" @click="selectResponsible('Group')">Gruppe</a></li>
                </ul>
                <input type="text" class="form-control" :disabled="!(state.template.responsibleType == 'Group')" v-model="state.template.responsible" :placeholder="state.template.responsibePlaceholder">
              </div>
            </div>

            <div class="col-sm-6">
              <label for="inputName" class="form-label">{{ getOffsetInDaysLabel() }}</label>
              <input type="number" class="form-control" v-model="state.template.offsetInDays" placeholder="Tage">
            </div>

         
          </div>
        </div>

        <!-- hack: I do not know why margin is not displayed when scrolled to the bottom... -->
        <div class="m-4">&nbsp;</div>

        </div>

      </div>
    </div>

    <!-- list of templates -->
    <div class="col-3 bg-light shadow">
      <div class="vh-100 overflow-auto">

        <div class="p-3">

          <!-- headline -->
          <h5>
            <i class="bi-eye pe-2 text-primary" role="img" ></i>Vorschau
          </h5>

          <!-- preview component -->
          <TaskPreview class="mt-4" :task="state.template"></TaskPreview>

        </div>

      </div>
    </div>

  </div>
</div>

</template>
  
<script>

import { useRoute, useRouter } from 'vue-router'
import { reactive, ref, watch } from 'vue'

import Toolbar from '@/components/Toolbar.vue'
import BusyIndicator from '@/components/BusyIndicator.vue'
import TaskPreview from './TaskPreview.vue'

import log from '@/modules/logging.js';
import api from '@/modules/api.js';

export default {
  name: 'TaskTemplate',
  components: {
     Toolbar,
     TaskPreview,
     BusyIndicator
  },
  setup() {

    //
    // keep track of route
    const route = useRoute();
    watch(() => route.path, () => {
      query();
    });
    const router = useRouter();

    const description = ref();
    const fileUpload = ref();

    const state = reactive({
      uploadBusy: false,
      busy: false,
      template: newTemplate(),
    });

    function query() {
      log.trace("TaskTemplate:query()");
      let uuid = extractUuidFromRoute();
      if (uuid && uuid != 'New') {
        queryByUuid(uuid);
      }
      else {
        state.template = newTemplate();
      }
    }

    function extractUuidFromRoute() {
      const path = route.path.split("/").filter(s => s.length > 0);
      if (path[0] == 'taskTemplates' && path[1]) {
        return path[1];
      }
      return null;
    }

    function queryByUuid(uuid) {
      api.getTaskTemplate(uuid).then(response => {
        log.trace("TaskTemplate:queryByUuid() Fetched task template: {}", response);
        state.template = response;
      });
    }

    function newTemplate() {
      return {
        type: "OnBoarding",
        media: null,
        headline: "Neue Aufgabe",
        description: "Beschreibung der Aufgabe...",
        duration: "",
        defaultAction: "JA, ERLEDIGT",

        responsibePlaceholder: null,
        responsibleType: null,
        responsible: null,
        offsetInDays: null,
      };
    }

    selectResponsible(null);

    function selectResponsible(responsibleType) {

      state.template.responsibleType = responsibleType;

      switch (responsibleType) {
        case 'Employee':
          state.template.responsible = "Mitarbeiter";
          state.template.responsibePlaceholder = null;
          break;
        case 'Manager':
          state.template.responsible = "Manager";
          state.template.responsibePlaceholder = null;
          break;
        case 'Group':
          state.template.responsible = null;
          state.template.responsibePlaceholder = "Email Gruppe oder Verantwortliche(r)";
          break;
        default:
          state.template.responsible = null;
          state.template.responsibePlaceholder = "Bitte auswählen...";
          break;
      }
    }

    function insertVar(placeholder) {

      //
      // add space
      if (state.template.description.length > 0) {
        if (!state.template.description.charAt(state.template.description.length - 1) == ' ') {
          state.template.description += " ";
        }
      }

      state.template.description += " " + placeholder;
    }

    function uploadMedia() {
      log.trace("TaskTemplate:uploadMedia() Uploading task template media data");
      state.uploadBusy = true;
      api.uploadMedia(fileUpload.value.files).then(response => { 
          log.trace("TaskTemplate:uploadProfilePic() task template media uploaded {}", response);
          state.template.media = response;
          state.uploadBusy = false;
      });
    }    

    function saveTaskTemplate() {
      log.trace("TaskTemplate:saveTaskTemplate() Save task template");
      state.busy = true;
      api.updateTaskTemplate(state.template).then(response => { 
          log.trace("TaskTemplate:saveTaskTemplate() task template updates {}", response);
          state.busy = false;
          state.template = response;
          router.replace({ path: '/taskTemplates/' + state.template.uuid });
      });
    }

    function getOffsetInDaysLabel() {
      switch (state.template.type) {
        case 'PreBoarding': return 'Vor Arbeitsbeginn';
        case 'OnBoarding': return 'Nach Arbeitsbeginn';
        case 'OffBoarding': return 'Vor Arbeitsende';
      }
    }

    query();

    return {
      fileUpload,
      description,
      state,
      selectResponsible,
      insertVar,
      uploadMedia,
      saveTaskTemplate,
      getOffsetInDaysLabel
    };
  }
};
</script>
