<template>

<Toolbar ref="toolbar" :activeMenu="'Settings'"/>

<div class="w-100 overflow-auto">

<div class="header sticky-top">

  <BusyIndicator :busy="state.busy"></BusyIndicator>

  <div class="container px-5 pt-5">

    <!-- Headline -->
    <h1 class="display-5">
      <i class="bi-gear pe-2 text-primary" role="img" aria-label="Appointments"></i>
      Einstellungen
    </h1>
    <span class="lead">Konfigurieren Sie Ihren Arbeitsplatz nach Ihren Vorstellungen</span>

  </div>

</div>

<div class="container g-3 px-5 pb-4">

  <div class="display-5 mt-5">Hallo {{ state.user.name }} {{ state.user.familyName }}</div>

  <!-- Personen daten -->
  <div class="mt-5 border shadow p-3">
    <div class="mb-2 d-flex align-items-center">
      <span class="fs-4 lead">Profil</span>
      <i class="bi-pencil btn btn-link" @click="edit(FORMS.Person)"></i>
    </div>

    <div class="row pt-2">

      <div class="col-lg-8">
        <form>
        <fieldset :disabled="state.edit != FORMS.Person">
          <label for="inputProfile" class="form-label">Ihr Profil</label>
          <textarea class="form-control" style="height:7rem" v-model="state.user.profile" id="inputProfile" type="text" maxlength="250"></textarea>
        </fieldset>
        </form>
      </div>

      <div class="col-lg-4 text-center">
        <img v-if="state.user.profilePic" :src="state.user.profilePic" style="width:5rem;height:5rem" class="mx-auto rounded-circle border border-primary border-2" />
        <div class="mt-4">
          <input ref="fileUpload" type="file" id="fileElem" multiple accept="*" style="display:none" @change="uploadProfilePic()">
          <button class="btn btn-primary" @click="fileUpload.click()">
            <span v-if="state.uploadBusy" class="spinner-border spinner-border-sm text-light me-2"></span>
            Profilbild hochladen
          </button>
        </div>
      </div>

    </div>

    <div class="row mt-4">
      <div v-if="state.edit == FORMS.Person" class="col-12 mb-2">
        <button type="button" class="btn btn-primary me-2" @click="save(FORMS.Person)">
          <span v-if="state.busy" class="spinner-border spinner-border-sm text-light me-2"></span>
          Speichern
        </button>
        <button type="button" class="btn btn-outline-secondary" @click="cancel()">Verwerfen</button>
      </div>
    </div>

  </div>

</div>
</div>
</template>

<script>

import { reactive, ref } from 'vue'

import Toolbar from '@/components/Toolbar.vue'
import BusyIndicator from '@/components/BusyIndicator';

import auth from '@/modules/auth.js';
import api from '@/modules/api.js';
import log from '@/modules/logging.js';

export default {
  name: 'Settings',
  components: {
    Toolbar,
    BusyIndicator
  },
  setup() {

    const FORMS = { None: 0, Person: 1, Schedule: 2 };

    const fileUpload = ref(null);

    const toolbar = ref(null);

    const state = reactive({
      
      busy: false,

      uploadBusy: false,

      edit: FORMS.None,

      user: {
        settings: {
          gracePeriod: null
        }
      }
    });

    function query() {
      return auth.getUser().then(user => { 
        state.user = user;
        log.trace("Settings:query() User profile loaded: {}", user);
      });
    }

    function uploadProfilePic() {
      log.trace("Settings:uploadProfilePic() Uploading profile picture");
      state.uploadBusy = true;
      api.setProfilePic(fileUpload.value.files).then(response => { 
          log.trace("Settings:uploadProfilePic() Profile picture uploaded");
          state.user = response;
          state.uploadBusy = false;
          toolbar.value.refresh();
      });
    }

    function edit(form) {
      state.edit = form;      
    }

    function save() {
      if (state.edit == FORMS.Person || state.edit == FORMS.Schedule) {
        state.busy = true;
        api.updateUser(state.user.uuid, state.user).then(response => {
          log.trace("Settings:Save() User profile stored");
          state.busy = false;
          state.edit = FORMS.None;
          state.user = response;
        });
      }
    }

    function cancel() {
      query().then(() => { state.edit = FORMS.None } );
    }

    query();

    return {
      FORMS,
      toolbar,
      state,
      edit,
      save,
      cancel,
      fileUpload,
      uploadProfilePic,
    };
  }
};
</script>