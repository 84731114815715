<template>

  <div class="row m-4">
    <TransitionGroup>
    <div class="py-4 col-md-12" v-for="task in state.tasks" :key="task" >
      <TaskPreview  :task="task" @onSelected="$emit('onSelected', $event)"></TaskPreview>
      <div class="mt-2">
        <!--
        <a href="" @click="$router.push({ path: `/taskTemplates/${task.uuid}` });">Editieren</a> | <a href="">Kopie editieren</a>
        -->
        <a href="" @click="$emit('onEdit', task.uuid);">Editieren</a> | <a href="" @click="$emit('onEditCopy', task.uuid);">Kopie editieren</a>
      </div>
    </div>
    </TransitionGroup>
  </div>

</template>

<script>

import { reactive } from 'vue'

import log from '../../modules/logging.js';
import api from '../../modules/api.js';

import TaskPreview from './TaskPreview.vue';

export default {
  name: 'BoardingTemplateSelection',
  components: {
    TaskPreview,
  },
  emits: [
    'onSelected',
    'onEdit',
    'onEditCopy',
  ],
  setup() {

    const state = reactive({
      tasks: []
    });

    function query() {
      api.queryTaskTemplates().then(response => { 
          log.trace("BoardingTemplateSelection:queryTaskTemplates() task template loaded {}", response);
          state.tasks = response;
      });
    }

    query();

    return {
      state,
    };
  }
};

</script>
