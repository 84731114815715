import log from '@/modules/logging.js';

class Tracking {

    constructor(app) {
        this.app = app;
        // log.trace("Tracking constructed {}", this.app.config.globalProperties);
    }

    trackEvent(page, event) {
        this.onMatomo().then(m => { 
            m.trackEvent(page, event);
            log.trace("Page '{}' fired event '{}'", page, event);
        });
    }

    onMatomo() {

        const theApp = this.app;

        return new Promise((function(resolve) {
            var start = Date.now();
            var interval = setInterval((function() {

                if (theApp.config.globalProperties.$matomo) {
                    clearInterval(interval);
                    resolve(theApp.config.globalProperties.$matomo);
                    console.log("Matomo was resolved from app properties");
                    return true;
                }

                if (Date.now() >= start + 5000) {
                    console.log("Matomo is not available");
                    throw clearInterval(interval), new Error("[vue-matomo]: window.Piwik undefined after waiting for ".concat(5000, "ms"));
                }
            }), 50);
        }))
    }
}

export default Tracking;