<template>

  <Transition name="remove">
  <div v-if="state.visible" class="p-2">

  <!-- open task -->
  <div class="card">
    <img :src="$props.task.mediaUrl" class="card-img-top" alt="...">

    <div class="card-body position-relative">

      <Transition>

        <div v-if="!state.done">
            <h6 class="card-subtitle mt-2 mb-2 text-muted">{{ $props.task.dueDate }} | Dauer ca. {{ $props.task.duration }}
            <span class="badge bg-warning ms-2">{{ $props.task.status }}</span>
          </h6>
          <h2 class="card-title mt-4">{{ $props.task.headline }}</h2>
          <p class="card-text mt-4">{{ $props.task.description }}</p>
          <a href="#" class="btn btn-primary w-100 mt-4" @click="setDone()">{{ $props.task.defaultAction }}</a>
        </div>

        <div v-else-if="state.done" class="position-absolute top-50 start-50 translate-middle">
          <h1 class="text-primary display-1 me-4 text-center"><i class="bi-check2-circle"></i>Done</h1>
        </div>

      </Transition>
    </div>

  </div>

</div>
</Transition>

</template>

<script>

import { reactive } from 'vue'

import api from '../../modules/api';
import log from '../../modules/logging';

export default {
  name: 'SimpleTask',
  props: { 
    task: {
      type: Object,
      default: null
    },
  },
  emits: [
    'onDone'
  ],
  setup(props, { emit }) {

    const state = reactive({
      done: false,
      visible: true
    });

    function setDone() {
      state.done = true;
      api.setTaskDone(props.task.uuid).then(response => {
        log.trace("SimpleTask:setDone() Set task to done. New tasklist: {}", response);
        setTimeout(() => { 
          log.trace("SimpleTask:setDone() Hide task");
          state.visible = false
          setTimeout(() => {
            log.trace("SimpleTask:setDone() Fire Done Event");
            emit('onDone', response);
          }, 500);
        }, 500);
      })
    }

    return {
      state,
      setDone,
    };
  }
};
</script>

<style>

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.remove-enter-active, .remove-leave-active {
  transition: all 0.5s ease;
}
.remove-enter-from, .remove-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}

</style>

