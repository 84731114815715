import fmt from './format';

export default {

  trace() {
    if (doLog('TRACE')) {
      let msg = toMessage(arguments);
      if (msg) { console.log(msg); }
    }
  },

  info() {
    if (doLog('INFO')) {
      let msg = toMessage(arguments);
      if (msg) { console.info(msg); }
    }
  },

  warn() {
    if (doLog('WARN')) {
      let msg = toMessage(arguments);
      if (msg) { console.warn(msg); }
    }
  },

  error() {
    if (doLog('ERROR')) {
      let msg = toMessage(arguments);
      if (msg) { console.error(msg); }
    }
  },
}


function toLevel(logLevel) {
  switch (logLevel) {
    case 'TRACE': return 0;
    case "INFO": return 1;
    case "WARN": return 2;
    case "ERROR": return 3;
    default: return 3;
  }
}


function doLog(logLevel) {
  return toLevel(logLevel) >= toLevel(process.env.VUE_APP_LOG_LEVEL);
}


function toMessage(parameters) {

  if (parameters.length == 0) {
    return null;
  }

  if (parameters.length == 1) {
    return String(parameters[0]);
  }

  let msg = `${fmt.fromMillisToDateTimeStamp(Date.now())} ${parameters[0]}`;

  for (let i = 1; i < parameters.length; i++) {

    let arg = String(parameters[i]);
    try {
      if (typeof parameters[i] === "object") {
        arg = JSON.stringify(parameters[i]);
      }
      else if (typeof parameters[i] === "function") {
        arg = parameters[i]();
      }
    }
    catch (e) {
      console.error("Exception converting argument to string", e.stack);
    }

    msg = msg.replace("{}", arg);
  }

  return msg;
}
