<template>

<div class="border shadow">
<slot></slot>
<table class="table table-hover rounded">

  <thead class="table-light">
    <tr>
      <th scope="col">Mitarbeiter</th>
      <th scope="col">Verantwortlich</th>
      <th scope="col">Aufgabe</th>
      <th scope="col">Status</th>
      <th scope="col"></th>
    </tr>
  </thead>

  <tbody>
    <TransitionGroup>
    <tr v-for="task in $props.tasks" :key="task">

      <td>
        {{ task.employee }}
      </td>

      <td>
        {{ task.responsible }}
      </td>

      <td>
        {{ task.headline }}
      </td>

      <td>
        <div v-if="task.status == 'Overdue'" class="badge p-2 bg-danger">{{ toStatus(task.status) + ' seit ' + task.dueDate }}</div>
        <div v-if="task.status == 'Upcoming'" class="badge p-2 bg-primary">{{ toStatus(task.status) + ' am ' + task.dueDate }}</div>
        <div v-if="task.status == 'Done'" class="badge p-2 bg-secondary">{{ toStatus(task.status) + ' am ' + task.completedAt }}</div>
      </td>

      <td>
        <i v-if="task.status == 'Overdue'" class="bi-bell text-primary" title="Erinnerung senden"></i>
      </td>

    </tr>
    </TransitionGroup>
  </tbody>
</table>
</div>

</template>

<script>

import { reactive } from 'vue'

export default {
  name: 'BoardingTasksTable',
  props: {
    tasks: {
      type: Array,
      default: null,
    }
  },
  setup() {

    const state = reactive({
      tasks: [],
    });

    function toStatus(status) {
      switch (status) {
        case 'Done': return "Abgeschlossen";
        case 'Overdue': return "Überfällig";
        case 'Upcoming': return "Demnächst";
      }
    }

    return {
      state,
      toStatus,
     };
  }
}
</script>
