import { createWebHistory, createRouter } from "vue-router";

import auth from '../modules/auth.js';

//
// login / users etc.
import Login from "@/views/Login.vue";

//
// dashboard
import Dashboard from "@/views/Dashboard.vue";

//
// onboarding
import BoardingTasks from "@/views/onboarding/BoardingTasks.vue";

import BoardingTemplateList from "@/views/onboarding/BoardingTemplateList.vue";
import BoardingTemplate from "@/views/onboarding/BoardingTemplate.vue";

import TaskTemplateList from "@/views/onboarding/TaskTemplateList.vue";
import TaskTemplate from "@/views/onboarding/TaskTemplate.vue";

import TaskView from "@/views/onboarding/TaskView.vue";

//
// settings
import Settings from "@/views/settings/Settings.vue";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/tasks/:uuid",
    name: "BoardingTasks",
    component: BoardingTasks,
  },
  {
    path: "/boardingTemplates",
    name: "BoardingTemplateList",
    component: BoardingTemplateList,
    beforeEnter: requireAuth
  },
  {
    path: "/boardingTemplates/:uuid",
    name: "BoardingTemplate",
    component: BoardingTemplate,
    beforeEnter: requireAuth
  },
  {
    path: "/taskTemplates",
    name: "TaskTemplateList",
    component: TaskTemplateList,
    beforeEnter: requireAuth
  },
  {
    path: "/taskTemplates/:uuid",
    name: "TaskTemplate",
    component: TaskTemplate,
    beforeEnter: requireAuth
  },
  {
    path: "/task/:uuid",
    name: "TaskView",
    component: TaskView,
  },
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    beforeEnter: requireAuth
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    beforeEnter: requireAuth
  },
  {
    path: "/*",
    component: 404
  },
];

function requireAuth (to, from, next) {
  auth.isAuthenticated().then(isAuthenticated => {
    if (isAuthenticated) {
      next()
    }
    else {
      next({
          path: '/login',
          query: { redirect: to.fullPath }
      })
    }
  });
}

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    const scrollBox = document.getElementById('scrollbox');
    // console.log("Scrolled box to top:" + scrollBox);
    if (scrollBox) {
      scrollBox.scrollTop = 0;
    }
  },
});


export default router;