<template>

<!--
<div class="d-flex w-100 vh-100">
-->

<Toolbar :activeMenu="'Dashboard'"/>

<div class="w-100 overflow-auto">

<div class="header">

  <div class="container px-5 pt-5">

    <!-- Headline -->
    <h1 class="display-5">
      <i class="bi-house pe-2 text-primary" role="img" aria-label="Appointments"></i>
      Hallo, {{ state.user.name + ' ' + state.user.familyName }}
    </h1>
    <span class="lead">Hier finden Sie alle wichtigen Informationen auf einen Blick</span>

  </div>
</div>

<div class="container px-5 py-5">

  <div class="row">
    <div class="col-4">
      <div class="p-4 bg-light shadow">
        <span class="text-muted">Überfällig<i class="bi-record-circle text-primary ms-2"></i></span>
        <div class="display-1">{{ state.stats.overdueTasks }}</div>
      </div>
    </div>
    <div class="col-4">
      <div class="p-4 bg-light shadow">
        <span class="text-muted">Demnächst<i class="bi-play-circle text-primary ms-2"></i></span>
        <div class="display-1">{{ state.stats.upcomingTasks }}</div>
      </div>
    </div>
    <div class="col-4">
      <div class="p-4 bg-light shadow">
        <span class="text-muted">Erledigt<i class="bi-check-circle text-primary ms-2"></i></span>
        <div class="display-1">{{ state.stats.doneTasks }}</div>
      </div>
    </div>
  </div>

  <!-- search and new buttons -->
  <div class="d-flex mt-5">

    <div class="input-group">
      <span class="input-group-text" id="basic-addon1"><i class="bi-search"></i></span>
      <input type="text" class="form-control me-2" placeholder="Nach Mitarbeiter oder Verantwortlichem suchen" v-model="state.search">
    </div>

    <!-- type selection-->
    <div class="btn-group" role="group" aria-label="Basic checkbox toggle button group">
      <input type="radio" class="btn-check" id="btncheck1" autocomplete="off" value="Overdue" v-model="state.status" @change="queryTasks()">
      <label class="btn btn-outline-primary text-nowrap" for="btncheck1">Überfällig</label>

      <input type="radio" class="btn-check" id="btncheck2" autocomplete="off" value="Upcoming" v-model="state.status" @change="queryTasks()">
      <label class="btn btn-outline-primary text-nowrap" for="btncheck2">Demnächst</label>

      <input type="radio" class="btn-check" id="btncheck3" autocomplete="off" value="Done" v-model="state.status" @change="queryTasks()">
      <label class="btn btn-outline-primary text-nowrap" for="btncheck3">Erledigt</label>
    </div>
  </div>

  <BoardingTasksTable :tasks="state.tasks" class="mt-4">
    <div class="bg-light p-3 fs-5">
      Übersicht Status Boarding Aufgaben
    </div>
  </BoardingTasksTable>

</div>
</div>

</template>

<script>

import { reactive } from 'vue';

import auth from '@/modules/auth.js';
import api from '@/modules/api.js';
import log from '@/modules/logging.js';

import Toolbar from '../components/Toolbar.vue'
import BoardingTasksTable from '@/views/onboarding/BoardingTasksTable'

export default {
  name: 'Dashboard',
  components: {
     Toolbar,
     BoardingTasksTable
  },
  setup() {

    const state = reactive({

      user: {},
      tasks: [],

      search: null,
      status: 'Overdue',
      stats: {
        overdueTasks: 0,
        doneTasks: 0,
        upcomingTasks: 0
      },
    });

    auth.getUser().then(response => { 
      state.user = response
    });

    function queryAll() {
      queryStats();
      queryTasks();
    }

    function queryStats() {
      api.getBoardingStats().then(response => {

        log.trace("Dashboard:query() Returned task stats {}", response);

        //
        // count up the numbers
        const duration = 500.0;
        const start = Date.now();
        const counter = setInterval(()=> {

          const passed = Date.now() - start;
          const progress = Math.min(passed / duration, 1);

          state.stats.overdueTasks = Math.round(response.overdueTasks * progress);
          state.stats.doneTasks = Math.round(response.doneTasks * progress);
          state.stats.upcomingTasks = Math.round(response.upcomingTasks * progress);

          if (passed >= duration) {
            clearInterval(counter);
          }
        }, 20);
      });
    }

    function queryTasks() {
      state.tasks = [];
      api.queryAllTasks(state.status, state.search).then(response => {
        log.trace("Dashboard:queryTasks() Fetched tasks: {}", response);
        state.tasks = response;
      });
    }

    queryAll();

    return {
      state,
      queryTasks
    };
  }
};
</script>