<template>

  <Toolbar :activeMenu="'TaskTemplates'"/>

  <div class="w-100 overflow-auto">
  
  <!-- header -->
  <div class="header">
      <div class="container px-5 pt-5">
      <h1 class="display-5">
        <i class="bi-check2-square pe-2 text-primary" role="img" ></i>
        Boarding Aufgaben
      </h1>
      <span class="lead">Verwalten Sie hier Ihre unterschiedlichen Pre-, On- und Offboarding Aufgaben</span>
      </div>
  </div>
  
  <!-- toolbar -->
  <div class="container px-5 pt-5">

    <!-- search and new buttons -->
    <div class="d-flex">
      <div class="input-group">
        <span class="input-group-text" id="basic-addon1"><i class="bi-search"></i></span>
        <input type="text" class="form-control me-2" placeholder="Aufgabe suchen" v-model="state.search">
      </div>
      <button class="btn btn-primary text-nowrap" @click="$router.push({ path: `/taskTemplates/New` });">Neue Boarding Aufgabe anlegen</button>
    </div>

    <!-- type selection-->
    <div class="btn-group mt-4" role="group" aria-label="Basic checkbox toggle button group">
      <input type="checkbox" class="btn-check" id="btncheck1" autocomplete="off" value="PreBoarding" v-model="state.types">
      <label class="btn btn-outline-primary" for="btncheck1">Pre-Boarding</label>

      <input type="checkbox" class="btn-check" id="btncheck2" autocomplete="off" value="OnBoarding" v-model="state.types">
      <label class="btn btn-outline-primary" for="btncheck2">On-Boarding</label>

      <input type="checkbox" class="btn-check" id="btncheck3" autocomplete="off" value="OffBoarding" v-model="state.types">
      <label class="btn btn-outline-primary" for="btncheck3">Off-Boarding</label>
    </div>

    <!-- view selection -->
    <div class="btn-group mt-4 float-end" role="group" aria-label="Basic radio toggle button group">
      <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" value="list" v-model="state.view">
      <label class="btn btn-outline-primary" for="btnradio1"><i class="bi-list"></i></label>

      <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" value="grid" v-model="state.view">
      <label class="btn btn-outline-primary" for="btnradio2"><i class="bi-grid-fill"></i></label>
    </div>

  </div>

  <!-- list -->
  <div class="container px-5 py-4">
    <div class="row g-4">
      <TransitionGroup>
        <TaskPreview class="col-lg-4" v-for="task in state.tasks" :key="task" :task="task" @onSelected="$router.push({ path: '/taskTemplates/' + task.uuid });"></TaskPreview>
      </TransitionGroup>
    </div>
  </div>

  </div>

</template>

<script>

import { reactive } from 'vue'

import log from '@/modules/logging.js'
import api from '@/modules/api.js'

import Toolbar from '@/components/Toolbar.vue'
import TaskPreview from './TaskPreview.vue'

export default {
  name: 'TaskTemplateList',
  components: {
     Toolbar,
     TaskPreview
  },
  setup() {

    const state = reactive({
      tasks: [],
      search: null,
      types: [ 'PreBoarding', 'OnBoarding', 'OffBoarding' ],
      view: 'grid'
    });

    function query() {
      api.queryTaskTemplates().then(response => { 
          log.trace("BoardingTemplateSelection:queryTaskTemplates() task template loaded {}", response);
          state.tasks = response;
      });
    }

    query();

    return {
      state
    };
  }
};
</script>
