<template>

<div class="row pt-3">

  <div class="col-sm-12">
    <h5>
        #{{ $props.order + 1}} {{ $props.step.taskTemplate.headline }}
        <a href="#" @click="$emit('onRemove', $props.order)"><i class="ms-3 bi-trash"></i></a>
        <a href=""><i class="ms-3 bi-eye"></i></a>
    </h5>
  </div>

  <div class="col-sm-6">
    <label for="inputName" class="form-label">Verantwortlich</label>
    <div class="input-group mb-3">
      <button class="btn btn-outline-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Auswahl</button>
      <ul class="dropdown-menu">
        <li><a class="dropdown-item" href="#" @click="selectResponsible('Employee')" @change="$emit('onChanged')">Mitarbeiter</a></li>
        <li><a class="dropdown-item" href="#" @click="selectResponsible('Manager')" @change="$emit('onChanged')">Manager</a></li>
        <li><a class="dropdown-item" href="#" @click="selectResponsible('Group')" @change="$emit('onChanged')">Gruppe</a></li>
      </ul>
      <input type="text" class="form-control" :disabled="!(state.responsibleType == 'Group')" v-model="state.responsible" :placeholder="state.responsibePlaceholder" @input="$emit('onChanged')">
    </div>
  </div>

  <div class="col-sm-6">
    <label for="inputName" class="form-label">{{ $props.offsetInDaysLabel }}</label>
    <input type="text" class="form-control" v-model="state.offsetInDays" @input="$emit('onChanged')" placeholder="Tage">
  </div>

</div>

</template>

<script>

// import log from '@/modules/logging.js';

import { reactive } from 'vue'

export default {
  name: 'BoardingTemplateStep',
  props: { 
    step: {
      type: Object
    },
    offsetInDaysLabel: {
      type: String
    },
    order: {
      type: Number
    }
  },
  emits: [
    'onRemove',
    'onChanged'
  ],
  setup(props) {

    const state = reactive({ 

      responsibePlaceholder: null,
      responsibleType: null,
      responsible: null,

      offsetInDays: props.step.offsetInDays,
    });

    selectResponsible(props.step.responsibleType, props.step.responsible);

    function selectResponsible(responsibleType, responsible) {

      state.responsibleType = responsibleType;

      switch (responsibleType) {
        case 'Employee':
          state.responsible = "Mitarbeiter";
          state.responsibePlaceholder = null;
          break;

        case 'Manager':
          state.responsible = "Manager";
          state.responsibePlaceholder = null;
          break;

        case 'Group':
          state.responsible = responsible;
          state.responsibePlaceholder = "Email Gruppe oder Verantwortliche(r)";
          break;

        default:
          state.responsible = null;
          state.responsibePlaceholder = "Bitte auswählen...";
          break;
      }
    }

    function getStep() {
      return {
        taskTemplate: {
          uuid: props.step.taskTemplate.uuid
        },
        responsibleType: state.responsibleType,
        responsible: state.responsible,
        offsetInDays: state.offsetInDays
      };
    }

    return {
      state,
      selectResponsible,
      getStep,
    };
  }
};
</script>
