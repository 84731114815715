<template>

<div class="clickable" @click="$emit('onSelected', $props.task)">

  <!-- open task -->
  <div class="glowing-border card">
    <span class="position-absolute badge bg-primary" style="left: 1rem; top: 1rem;">
      {{ state.preview.type }}
    <span class="visually-hidden">unread messages</span>
    </span>
    <img :src="state.preview.media" class="card-img-top" alt="...">
    <div class="card-body">
      <h6 class="card-subtitle mt-2 mb-2 text-muted">bis {{ state.preview.dueDate }} | {{ state.preview.duration ? 'Dauer ca. ' + state.preview.duration : '' }} 
        <span class="badge bg-secondary ms-2">Demnächst</span>
      </h6>
      <h3 class="card-title mt-4">{{ state.preview.headline }}</h3>
      <p class="card-text mt-4">{{ state.preview.description }}</p>
      <a href="#" class="btn btn-primary w-100 mt-4">{{ state.preview.defaultAction }}</a>
    </div>
  </div>

</div>

</template>

<script>

import log from '@/modules/logging.js';
import assets from '@/modules/assets.js';

import { reactive, computed } from 'vue';

export default {
  name: 'TaskPreview',
  props: { 
    task: {
      type: Object,
      default: null
    },
  },
  emits: [
    'onSelected'
  ],
  setup(props) {

    log.info("TaskPreview:setup() API Endpoint: {}", process.env.VUE_APP_API_ENDPOINT);

    const state = reactive({
      preview: {
        media: computed(() => props.task.media ? `${process.env.VUE_APP_API_ENDPOINT}/public/v1/assets?key=${props.task.media}` : assets.imageAlt),
        type: computed(() => props.task.type),
        dueDate: "31.12.2025",
        headline: computed(() => render(props.task.headline)),
        description: computed(() => render(props.task.description)),
        duration: computed(() => props.task.duration),
        defaultAction: computed(() => props.task.defaultAction),
      },
    });

    const sampleContext = {
      person: {
        title: 'Frau',
        firstName: 'Ute',
        lastName: 'Müller',
        fullName: 'Ute Müller',
        email: 'ute.mueller@example.com',
        privateEmail: 'u_mueller_1981@gmx.com',
        startDate: '01.01.2023',
        endDate: '01.01.2024',
        role: 'iOS Entwicklerin',
        department: 'Gesundheit'
      }
    }

    const varEx = /{{(.*?)}}/;

    function render(str) {

      var found = str.match(varEx);
      while (found) {

        var prop = found[1].trim();
        var val = getProp(sampleContext, prop);
        str = str.replace(found[0], val ? val : "[Invalid attribute: '" + prop + "']");

        found = str.match(varEx);
      }

      return str;
    }

    function getProp(obj, desc) {
      const arr = desc.split('.');
      while (arr.length) {
        obj = obj[arr.shift()];
      }
      return obj;
    }    

    return {
      state,
    };
  }
};
</script>

<style>

.glowing-border:hover {
  outline: none;
  border-color: #0176D9;
  box-shadow: 0 0 15px #0176D9;
}

</style>
