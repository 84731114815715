<template>

<div id="scrollbox" class="w-100 overflow-auto">
<div class="container">

  <!-- navigation -->
  <div class="mt-4 mb-4">

    <button type="button" @click="setStatus('Overdue')" class="btn position-relative" :class="[ state.status == 'Overdue' ? 'btn-primary' : 'btn-outline-primary' ]">
      Offene Aufgaben
      <span v-if="state.overdueTasksCount > 0" class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
        {{ state.overdueTasksCount }}<span class="visually-hidden">Offen</span>
      </span>
    </button>

    <button type="button" @click="setStatus('Upcoming')" class="btn ms-4" :class="[ state.status == 'Upcoming' ? 'btn-primary' : 'btn-outline-primary' ]">
      Demnächst <span class="badge bg-secondary">{{ state.upcomingTasksCount }}</span>
    </button>
    
    <button type="button" @click="setStatus('Done')" class="btn ms-4" :class="[ state.status == 'Done' ? 'btn-primary' : 'btn-outline-primary' ]">
      Erledigt <span class="badge bg-secondary">{{ state.doneTasksCount }}</span>
    </button>    

    <Transition>
      <div v-if="state.selectedTasks.length > 3" class="float-end">
        <button class="btn btn-primary" type="button" @click="prev()"><i class="bi-caret-left-fill"></i></button>
        <button class="btn btn-primary ms-2" type="button" @click="next()"><i class="bi-caret-right-fill"></i></button>
      </div>
    </Transition>

  </div>

  <!-- tasks -->
  <div ref="taskList" class="d-flex flex-nowrap flex-row overflow-hidden">

    <!-- no tasks -->
    <div v-if="state.selectedTasks.length == 0" class="col-12">
      <div class="border rounded bg-light text-center align-middle p-4" style="min-height: 700px">
        <div><h1><i class="bi-check-circle text-primary"></i></h1></div>
        <div class="display-6 align-middle m-auto">Sie haben aktuell keine {{ state.translatedStatus }} Aufgaben</div>
      </div>
    </div>

    <!-- tasks -->
    <template v-if="state.status == 'Overdue'">
      <SimpleTask class="col-4" v-for="task in state.overdueTasks" :key="task" :task="task" @onDone="state.tasks = $event"></SimpleTask>
    </template>
    <template v-else-if="state.status == 'Upcoming'">
      <SimpleTask class="col-4" v-for="task in state.upcomingTasks" :key="task" :task="task" @onDone="state.tasks = $event"></SimpleTask>
    </template>
    <template v-else-if="state.status == 'Done'">
      <SimpleTask class="col-4" v-for="task in state.doneTasks" :key="task" :task="task" @onDone="state.tasks = $event"></SimpleTask>
    </template>

  </div>

</div>
</div>

</template>

<script>


import { useRoute } from 'vue-router'
import { reactive, ref, computed } from 'vue'

import api from '../../modules/api';
import log from '../../modules/logging';

import SimpleTask from './SimpleTask.vue';

export default {
  name: 'BoardingTasks',
  components: {
    SimpleTask,
  },
  setup() {

    const route = useRoute();

    const taskList = ref();

    const state = reactive({

      status: "Overdue",
      translatedStatus: computed(() => {
        switch (state.status) {
          case 'Overdue': return "offenen";
          case 'Upcoming': return "demnächst fälligen";
          case 'Done': return "erledigten";
          default: return "offenen";
        }
      }),

      tasks: [],

      selectedTasks: computed(() => state.tasks.filter(task => task.status == state.status )),

      overdueTasks: computed(() => state.tasks.filter(task => task.status == 'Overdue' )),
      overdueTasksCount: computed(() => state.tasks.filter(task => task.status == 'Overdue' ).length ),

      upcomingTasks: computed(() => state.tasks.filter(task => task.status == 'Upcoming' )),
      upcomingTasksCount: computed(() => state.tasks.filter(task => task.status == 'Upcoming' ).length ),

      doneTasks: computed(() => state.tasks.filter(task => task.status == 'Done' )),
      doneTasksCount: computed(() => state.tasks.filter(task => task.status == 'Done' ).length ),

      firstTask: 0
    });

    function setStatus(newStatus) {
      taskList.value.scrollLeft = 0;
      state.firstTask = 0;
      setTimeout(() => {
        state.status = newStatus;
      }, 1);
    }

    function next() {

      if (!canGoNext()) {
        return;
      }

      let cardWidth = taskList.value.children[0].offsetWidth;

      //
      // manual ease-in... yeah! 
      let i = setInterval(function(start, timeout) {

        let now = Date.now();
        let progress = Math.min(easeInSine((now - start) / timeout), 1);

        //
        // scroll
        taskList.value.scrollLeft = (state.firstTask * cardWidth) + (progress * cardWidth);

        //
        // stop after time timeout
        if (start + timeout < Date.now()) {
          state.firstTask++;
          clearInterval(i);
        }

      }, 5, Date.now(), 250);
    }

    function canGoNext() {
      let visibleCards = taskList.value.clientWidth / taskList.value.children[0].offsetWidth;
      return state.firstTask < state.tasks.length - visibleCards;
    }

    function prev() {

      if (state.firstTask == 0) {
        return;
      }

      let cardWidth = taskList.value.children[0].offsetWidth;

      //
      // manual ease-in... yeah! 
      let i = setInterval(function(start, timeout) {

        let now = Date.now();
        let progress = 1 - Math.max(easeInSine((now - start) / timeout), 0);

        //
        // scroll
        taskList.value.scrollLeft = ((state.firstTask - 1) * cardWidth) + (progress * cardWidth);

        //
        // stop after time timeout
        if (start + timeout < Date.now()) {
          state.firstTask--;
          clearInterval(i);
        }

      }, 5, Date.now(), 250);
    }

    /**
     *  see: https://easings.net/#easeInSine
     */
    function easeInSine(x) {
       return 1 - Math.cos((x * Math.PI) / 2);
    }

    function query() {

      var boardingUuid = extractUuidFromRoute();
      log.trace("TaskList:query() Query tasks for boarding {}: {}", boardingUuid, route.query.role);

      api.queryTasks(boardingUuid, route.query.role).then(response => {

        log.trace("TaskList:query() Fetched tasks for boarding {}: {}", boardingUuid, response);
        state.tasks = response;

        if (state.overdueTasksCount > 0) {
          state.status = 'Overdue';
        }
        else if (state.upcomingTasksCount > 0) {
          state.status = 'Upcoming';
        }
        else if (state.doneCount > 0) {
          state.status = 'Done';
        }
        else {
          state.status = 'Overdue';
        }
      });
    }

    function extractUuidFromRoute() {
      const path = route.path.split("/").filter(s => s.length > 0);
      if (path[0] == 'tasks' && path[1]) {
        return path[1];
      }
      return null;
    }

    query();

    return {
      taskList,
      state,
      setStatus,
      next,
      canGoNext,
      prev,
    };
  }
};
</script>

<style>

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

</style>
